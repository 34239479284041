/* home.css */
  
.home-page {
    width: 100vw;
    height: 100vh;
    background-image: url('../../images/bgedge.png');
    background-size: cover;
    background-position: center;
    position: relative;
    overflow-y: auto; /* Allows scrolling when content exceeds viewport height */
    color: white;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;  
  }

  .logos {
    scale: 70%;
  }
  
  a:visited {
    color: aqua;
  }

  .home-page-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;  
  }

  .fade-text-1,
  .fade-text-2 {
    color: white;
    animation: fade 3s infinite;
    margin-bottom: -20px;
    font-size: 48px;
  }
  
  @keyframes fade {
    0%, 100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
